import TableFilter from "../../../../../components/table-filter/components/TableFilter"
import *  as Yup from "yup"
import { GameLogIndexParams, GameLogTableRow } from "../../Models"
import { GAME_LOG_INDEX_URL, gameLogIndex } from "../../../Requests"
import { useState } from "react"
import { ApiResponse, FinalRequest, RequestPagination, RequestSort, ResponsePagination } from "../../../../../helpers/types/api-types"
import TablePagination, { initialPagination } from "../../../../../components/TablePagination/TablePagination"
import { TableContent } from "../../../../../components/Table"
import { getSort } from "../../../../../helpers/functions"
import TableRows from "./TableRows"
import { checkRequestResponse, formatDateTime, removeEmptyFilters } from "../../../../../components/Helpers"
import MessageModal from "../../../../../components/MessageModal"
import moment from "moment"
import { DATE_TIME_FORMAT } from "../../../../../helpers/constants"

type SortableColumn = 'timestamp'

const initialSort: RequestSort<SortableColumn> = {
  sort: 'timestamp',
  order: 'desc'
}

const Table = (props: { className?: any }) => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [gameLogs, setGameLogs] = useState<GameLogTableRow[]>([])
    const [pagination, setPagination] = useState<ResponsePagination>(initialPagination)
    const [filters, setFilters] = useState<FinalRequest<GameLogIndexParams>>({})
    const [sort, setSort] = useState<RequestSort<SortableColumn>>(initialSort)

    const onResponseHandler = (response: ApiResponse<GameLogTableRow[]>, params: FinalRequest<GameLogIndexParams>) => {
        let gameLogs: GameLogTableRow[]
        let pagination: ResponsePagination

        if ('paginations' in response.data) {
            gameLogs = response.data.rows ?? []
            pagination = response.data.paginations
        } else {
            gameLogs = response.data
            pagination = initialPagination
        }

        const rows: GameLogTableRow[] = gameLogs.map((log: GameLogTableRow): GameLogTableRow => ({
            ...log,
            timestamp: formatDateTime(log.timestamp)
        }))

        setGameLogs(rows)
        setPagination(pagination)
        setFilters(params)
        setLoading(false)
    }

    const sortColumnHandler = (event: React.MouseEvent<HTMLElement>) => {
        setSort(getSort(event))
    }

    const filterTable = async (filterParams: FinalRequest<GameLogIndexParams>) => {
        setLoading(true)
    
        try {
            const newFilters = removeEmptyFilters(filterParams)
            const response = await gameLogIndex(newFilters)
        
            if (!checkRequestResponse(response)) return
        
            const apiResponse: ApiResponse<GameLogTableRow[]> = response.data
            onResponseHandler(apiResponse, newFilters)
        
            return true
        } catch (err) {
            console.error(err)
            setLoading(false)
            MessageModal({ type: 'failed' })
            return false
        }
    }

    return (
        <>
            <TableFilter<GameLogIndexParams, GameLogTableRow[]>
                fields={[
                    {
                        label: 'Game Code',
                        name: 'game_code',
                        type: 'text',
                    },
                    {
                        label: 'Action',
                        name: 'action',
                        type: 'select',
                        options: [
                            { value: '', label: 'All' },
                            { value: 'create', label: 'Create' },
                            { value: 'update', label: 'Update' }
                        ]
                    },
                    {
                        label: 'From',
                        name: 'start_date_time',
                        type: 'date-time',
                        defaultValue: moment().subtract(7, "day").startOf("day").format(DATE_TIME_FORMAT),
                    },
                    {
                        label: 'To',
                        name: 'end_date_time',
                        type: 'date-time',
                        defaultValue: moment().endOf("day").format(DATE_TIME_FORMAT)
                    },
                ]}
                validationSchema={Yup.object().shape({
                    code: Yup.string(),
                    action: Yup.string(),
                })}
                apiUrl={GAME_LOG_INDEX_URL}
                onSubmit={() => setLoading(true)}
                onResponse={onResponseHandler}
                sort={sort}
                pagination={{
                paginate: 1,
                per_page: pagination.per_page,
                page: pagination.current_page
                }}
            />

            <TableContent
                name='game log'
                columns={[
                    { title: "GP Code", name: "game_provider_code", sort: false },
                    { title: "Category Code", name: 'category_code', sort: false },
                    { title: "Game Code", name: 'game_code', sort: false },
                    { title: "Game Icon", name: 'game_icon', sort: false },
                    { title: "Status", name: 'status', sort: false },
                    { title: "Details", name: 'details', sort: false },
                    { title: "Action", name: "action", sort: false },
                    { title: "Action By", name: "action_by", sort: false },
                    { title: "Updated At", name: "timestamp", sort: true },
                    { title: "Success", name: "success", sort: false },
                ]}
                contents={gameLogs}
                isLoading={isLoading}
                filters={filters}
                sortHandler={sortColumnHandler}
                tableRows={!isLoading && gameLogs.length > 0 && gameLogs.map(gameLog => {
                    return <TableRows
                        key={gameLog._id}
                        gameLog={gameLog}
                    />
                    })}
                filterTable={filterTable}
                setFilters={setFilters}
            />
            {
                pagination &&
                <TablePagination
                    pagination={pagination}
                    onPaginate={(paginationData: RequestPagination) => {
                    filterTable({
                        ...filters,
                        ...paginationData
                    })
                    setFilters(prevFilters => ({
                        ...prevFilters,
                        ...paginationData
                    }))
                }}
                />
            }
        </>
    )
}

export default Table