import axios from "axios";
import { CurrencyLogIndexParams } from "./currency/Models";
import {LoginLogIndexParams} from "./login/Models";
import { PermissionLogIndexParams } from "./permission/Models";
import {RoleHistoryFilter, RoleHistoryObject} from "./role/Models";
import { TransferLogIndexParams } from "./transfer/Models";
import { GameLogIndexParams } from "./game/Models";

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_LOG_INDEX_URL = `${API_URL}/log/login`
export const ROLE_LOG_INDEX_URL = `${API_URL}/log/role`
export const CURRENCY_LOG_INDEX_URL = `${API_URL}/log/currency`
export const PERMISSION_LOG_INDEX_URL = `${API_URL}/log/permission`
export const TRANSFER_LOG_INDEX_URL = `${API_URL}/log/transfer`
export const GAME_LOG_INDEX_URL = `${API_URL}/log/game`


export const loginLogIndex = (filters: LoginLogIndexParams) => {
  return axios.post(
    LOGIN_LOG_INDEX_URL,
    {...filters}
  )
}

export const roleLogIndex = (filters: RoleHistoryFilter) => {
  return axios.post(
    ROLE_LOG_INDEX_URL,
    {...filters}
  )
}

export const currencyLogIndex = (filters: CurrencyLogIndexParams) => {
  return axios.post(
    CURRENCY_LOG_INDEX_URL,
    {...filters}
  )
}

export const permissionLogIndex = (filters: PermissionLogIndexParams) => {
  return axios.post(
    PERMISSION_LOG_INDEX_URL,
    {...filters}
  )
}

export const transferLogIndex = (filters: TransferLogIndexParams) => {
  return axios.post(
    TRANSFER_LOG_INDEX_URL,
    {...filters}
  )
}

export const gameLogIndex = (filters: GameLogIndexParams) => {
  return axios.post(
    GAME_LOG_INDEX_URL,
    {...filters}
  )
}