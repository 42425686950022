import { GameLogTableRow } from "../../Models";
import TableRow, { TR_STYLE } from "../../../../../components/TableRow";

interface propsParams {
    gameLog: GameLogTableRow;
}
const TableRows = (props: propsParams) => {
    const { gameLog } = props

    const convertStatus = (value: number | string, badge = false) => {
        let status
        if (!badge) {
            switch (value) {
                case 1:
                    return status = 'active'
                case 2:
                    return status = 'pending'
                default:
                    return status = 'inactive'
            }
        } else {
            switch (value) {
                case 'success':
                case 1:
                    return status = 'success'
                case 'pending':
                case 2:
                    return status = 'warning'
                default:
                    return status = 'danger'
            }
        }
    }

    let status = convertStatus(gameLog.status)
    let status_badge = convertStatus(gameLog.status, true)

    const details: string = JSON.stringify({
        'game_id': gameLog.game_id,
        'game_sub_category_name': gameLog.game_sub_category_name,
        'game_name': gameLog.game_name, 
        'image_path': gameLog.image_path,
        'frb_code': gameLog.frb_code,
        'new': gameLog.new,
    }, null, 2)


    return (
        <TableRow
            key={gameLog._id}
            styles={[TR_STYLE.HOVER_ROW]}
            contents={[
                {key: gameLog._id + '_game_provide_code', detail: gameLog.game_provider_code},
                {key: gameLog._id + '_game_category_code', detail: gameLog.game_category_code},
                {key: gameLog._id + '_game_code', detail: gameLog.game_code},
                {key: gameLog._id + '_game_icon', 
                extra: gameLog.image_path === null || gameLog.image_path === '-'  
                ? <span className={`fs-6 ms-2 mb-1 text`} style={{color:'red'}}>
                ❗️ No Image
                </span> 
                : <img src={gameLog.image_path} style={{ width: '100px', height: '100px' }}/>
                },
                {key: gameLog._id + '_status', badges: [{text: status, colour: status_badge}]},
                {key: gameLog._id + '_details', detail: <pre className='p-2 code-snippet min-w-500px mw-300px' >{details}</pre>},
                {key: gameLog._id + '_action', detail: gameLog.action},
                {key: gameLog._id + '_action_by', detail: gameLog.action_by},
                {key: gameLog._id + '_updated_at', detail: gameLog.timestamp},
                {key: gameLog._id + '_success', badges: [{text: gameLog.success ? 'True' : 'False', colour: gameLog.success ? 'success' : 'danger'}]},
            ]}
        />
    )
}

export default TableRows