import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import TestResultPage from "../modules/test-plan/result/TestResultPage";
import TestSessionPage from "../modules/test-plan/session/TestSessionPage";
import TestTemplatePage from "../modules/test-plan/template/TestTemplatePage";
import { DashboardWrapper } from '../pages/dashboard/components/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from "../modules/auth";
import { ToolbarContextProvider } from "../store/toolbar-context";
import AgentPtPage from "../modules/position-taking/agent/AgentPtPage";
import InvoicePage from "../modules/invoices/InvoicePage";
import SgpMaintenancePage from "../modules/maintenance-site-provider/SgpMaintenancePage";
import TxnCountReportPage from '../modules/reports/txn-count-report/TxnCountReportPage';
import GameLogPage from '../modules/logs/game/GameLogPage';

const PrivateRoutes = () => {
  const UserProfilePage = lazy(() => import('../modules/userProfile/UserProfilePage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AgentsPage = lazy(() => import('../modules/agents/AgentsPage'))
  const RolePage = lazy(() => import('../modules/roles/RolePage'))
  const PermissionPage = lazy(() => import('../modules/permissions/PermissionPage'))
  const SitePage = lazy(() => import('../modules/sites/SitePage'))
  const CurrenciesPage = lazy(() => import('../modules/currencies/CurrencyPage'))
  const UserPage = lazy(() => import('../modules/users/UserPage'))
  const SubAccountPage = lazy(() => import('../modules/sub-accounts/SubAccountPage'))
  const LoginLogPage = lazy(() => import('../modules/logs/login/LoginPage'))
  const RoleLogPage = lazy(() => import('../modules/logs/role/RoleLogPage'))
  const CurrencyLogPage = lazy(() => import('../modules/logs/currency/CurrencyLogPage'))
  const PermissionLogPage = lazy(() => import('../modules/logs/permission/PermissionLogPage'))
  const TransferLogPage = lazy(() => import('../modules/logs/transfer/TransferLogPage'))
  const GameProviderPage = lazy(() => import('../modules/game-providers/GameProviderPage'))
  const ReportByPage = lazy(() => import('../modules/reports/report-by/ReportByPage'))
  const SettlementReportPage = lazy(() => import('../modules/reports/settlement-report/SettlementReportPage'))
  const SettlementBreakdownReportPage = lazy(() => import('../modules/reports/settlement-breakdown/SettlementBreakdownReportPage'))
  const SettlementReportIndexPage = lazy(() => import('../modules/reports/settlement-index/SettlementReportIndexPage'))
  const SettlementReportTabPage = lazy(() => import('../modules/reports/settlement-tab/SettlementReportTabPage'))
  const TransactionListPage = lazy(() => import('../modules/reports/transaction-list/TransactionListPage'))
  const AgentCreatePage = lazy(() => import('../modules/agents/components/create/AgentCreatePage'))
  const AgentSitePTPage = lazy(() => import('../modules/position-taking/agent-site/AgentSitePTPage'))
  const CompanyPtPage = lazy(() => import('../modules/position-taking/company/CompanyPtPage'))
  const FinanceReportPage = lazy(() => import('../modules/reports/finance-report/FinanceReportPage'))
  const GamePage = lazy(() => import('../modules/games/GamePage'))
  const PremiumGameReportPage = lazy(() => import('../modules/reports/premium-game-report/PremiumGameReportPage'))
  const SiteReport = lazy(() => import('../modules/reports/site-report/SiteReportPage'))
  const TransferPage = lazy(() => import('../modules/transfers/TransferPage'))
  const MaintenancePage = lazy(() => import('../modules/maintenance/MaintenancePage'))
  const SiteSettingsPage = lazy(() => import('../modules/site-settings/SiteSettingsPage'))
  const SiteGameProviderPage = lazy(() => import('../modules/site-game-provider/SiteGameProviderPage'))
  const SiteSummaryPage = lazy(() => import('../modules/reports/site-summary/SiteSummaryPage'))
  const PDPDIndexPage = lazy(() => import('../modules/reports/pdpd/PDPDIndexPage'))
  const FreeRoundBonus = lazy(() => import('../modules/free-round-bonus/FreeRoundBonus'))
  const FreeRoundBonusRequest = lazy(() => import('../modules/free-round-bonus-request/FreeRoundBonusRequest'))
  const VendorBot = lazy(() => import('../modules/vbot/VBotPage'))

  const auth = useAuth()

  return (
    <ToolbarContextProvider>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />

          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />

          {/* Lazy Modules */}
          {
            auth.hasPermission('free-round-bonus-view') &&
            <Route
              path='free-round-bonus/*'
              element={
                <SuspensedView>
                  <FreeRoundBonus />
                </SuspensedView>
              }
            />
          }
{
            auth.hasPermission('free-round-bonus-view') &&
            <Route
              path='free-round-bonus-requests/*'
              element={
                <SuspensedView>
                  <FreeRoundBonusRequest />
                </SuspensedView>
              }
            />
          }


          <Route
            path='user-profile/*'
            element={
              <SuspensedView>
                <UserProfilePage />
              </SuspensedView>
            }
          />

          <Route
            path='agents/*'
            element={
              <SuspensedView>
                <AgentsPage />
              </SuspensedView>
            }
          />
          <Route
            path='agents/create/*'
            element={
              <SuspensedView>
                <AgentCreatePage />
              </SuspensedView>
            }
          />
          <Route
            path='agents/update/*'
            element={
              <SuspensedView>
                <AgentCreatePage />
              </SuspensedView>
            }
          />
          <Route path='agents/position-takings' element={<Navigate to='/agents' />} />
          <Route
            path='agents/position-takings/*'
            element={
              <SuspensedView>
                <AgentPtPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />

          {
            auth.hasPermission('role-view') &&
            <Route
              path='/roles/*'
              element={
                <SuspensedView>
                  <RolePage />
                </SuspensedView>
              }
            />
          }

          {/* Permission Routes */}
          {
            auth.hasPermission('permission-view') &&
            <Route
              path='/permissions/*'
              element={
                <SuspensedView>
                  <PermissionPage />
                </SuspensedView>
              }
            />
          }

          {/* Currencies Routes */}
          {
            auth.hasPermission('currency-view') &&
            <Route
              path='/currencies/*'
              element={
                <SuspensedView>
                  <CurrenciesPage />
                </SuspensedView>
              }
            />
          }

          {/* Sites Routes */}
          {
            auth.hasPermission('site-view') &&
            <Route
              path='/sites/*'
              element={
                <SuspensedView>
                  <SitePage />
                </SuspensedView>
              }
            />
          }

          {/* Admin Routes */}
          {
            auth.hasPermission('user-view') &&
            <Route
              path='/users/*'
              element={
                <SuspensedView>
                  <UserPage />
                </SuspensedView>
              }
            />
          }

          {/* Sub Account Routes */}
          {
            auth.hasPermission('sub-account-view') &&
            <Route
              path='/sub-accounts/*'
              element={
                <SuspensedView>
                  <SubAccountPage />
                </SuspensedView>
              }
            />
          }

          {/* Log Routes */}
          {/* Login Log */
            auth.hasPermission('login-log-view') &&
            <Route
              path='/log/login/*'
              element={
                <SuspensedView>
                  <LoginLogPage />
                </SuspensedView>
              }
            />
          }
          {/* Role Log */
            auth.hasPermission('role-log-view') &&
            <Route
              path='/log/role/*'
              element={
                <SuspensedView>
                  <RoleLogPage />
                </SuspensedView>
              }
            />
          }

          {/* Currency Log */
            auth.hasPermission('currency-log-view') &&
            <Route
              path='/log/currency/*'
              element={
                <SuspensedView>
                  <CurrencyLogPage />
                </SuspensedView>
              }
            />
          }

          {/* Permission Log */
            auth.hasPermission('permission-log-view') &&
            <Route
              path='/log/permission/*'
              element={
                <SuspensedView>
                  <PermissionLogPage />
                </SuspensedView>
              }
            />
          }

          {/* Transfer Log */
            auth.hasPermission('transfer-log-view') &&
            <Route
              path='/log/transfer/*'
              element={
                <SuspensedView>
                  <TransferLogPage />
                </SuspensedView>
              }
            />
          }

          {/* Game Log */
            auth.hasPermission('game-log-view') &&
            <Route
              path='/log/game/*'
              element={
                <SuspensedView>
                  <GameLogPage />
                </SuspensedView>
              }
            />
          }

          {/* Game Provider Routes */}
          {
            auth.hasPermission('game-provider-view') &&
            <Route
              path='/game-providers/*'
              element={
                <SuspensedView>
                  <GameProviderPage />
                </SuspensedView>
              }
            />
          }

          {/* Maintenance Routes */}
          {
            auth.hasPermission('maintenance-view') &&
            <>
              <Route
                path='/maintenance/provider/*'
                element={
                  <SuspensedView>
                    <MaintenancePage />
                  </SuspensedView>
                }
              />
              <Route
                path='/maintenance/site-provider/*'
                element={
                  <SuspensedView>
                    <SgpMaintenancePage />
                  </SuspensedView>
                }
              />
            </>
          }

          {/* Game Routes*/}
          {
            auth.hasPermission('game-view') &&
            <Route
              path='/games/*'
              element={
                <SuspensedView>
                  <GamePage />
                </SuspensedView>
              }
            />
          }

          {/* Report Routes */}
          {
            (auth.hasPermission('vendor-bot-settlement-update') || auth.hasPermission('vendor-bot-settlement-view')) &&
            <Route
              path='/report/settlement-vbot/*'
              element={
                <SuspensedView>
                  <VendorBot />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('settlement-report') &&
            <Route
              path='/report/report-by/*'
              element={
                <SuspensedView>
                  <ReportByPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('settlement-report') &&
            <Route
              path='/report/transaction-list/*'
              element={
                <SuspensedView>
                  <TransactionListPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('settlement-report') &&
            <Route
              path='/report/settlement/*'
              element={
                <SuspensedView>
                  <SettlementReportPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('settlement-report') &&
            <Route
              path='/report/settlement-index/*'
              element={
                <SuspensedView>
                  <SettlementReportIndexPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('settlement-report') &&
            <Route
              path='/report/settlement-tab/*'
              element={
                <SuspensedView>
                  <SettlementReportTabPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('report-status') &&
            <Route
              path='/report/pdpd/*'
              element={
                <SuspensedView>
                  <PDPDIndexPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('finance-report') &&
            <Route
              path='/report/merchant-report/*'
              element={
                <SuspensedView>
                  <SettlementBreakdownReportPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('finance-report') &&
            <Route
              path='/report/finance-report/*'
              element={
                <SuspensedView>
                  <FinanceReportPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('finance-report') &&
            <Route
              path='/report/site-report/*'
              element={
                <SuspensedView>
                  <SiteReport />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('finance-report') &&
            <Route
              path='/report/premium-game-report/*'
              element={
                <SuspensedView>
                  <PremiumGameReportPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('finance-report') &&
            <Route
              path='/report/site-summary/*'
              element={
                <SuspensedView>
                  <SiteSummaryPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('finance-report') &&
            <Route
              path='/report/txn-count/*'
              element={
                <SuspensedView>
                  <TxnCountReportPage />
                </SuspensedView>
              }
            />
          }

          {/* Invoices */}
          {
            auth.hasPermission('invoice-view') &&
            <Route
              path='/invoices/*'
              element={
                <SuspensedView>
                  <InvoicePage />
                </SuspensedView>
              }
            />
          }

          {/* Position Taking */}
          {
            auth.hasPermission('company-pt-set') &&
            <Route
              path='/position-taking/company/*'
              element={
                <SuspensedView>
                  <CompanyPtPage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('agent-site-pt-set') &&
            <Route
              path='/position-taking/agent-site/*'
              element={
                <SuspensedView>
                  <AgentSitePTPage />
                </SuspensedView>
              }
            />
          }

          {/* Transfer */}
          {
            auth.hasPermission('transfer-view') &&
            <Route
              path='/transfers/*'
              element={
                <SuspensedView>
                  <TransferPage />
                </SuspensedView>
              }
            />
          }

          {/* Test Plans */}
          {
            auth.hasPermission('test-template-view') &&
            <Route
              path='/test-plan/templates/*'
              element={
                <SuspensedView>
                  <TestTemplatePage />
                </SuspensedView>
              }
            />
          }
          {
            auth.hasPermission('test-session-view') &&
            <>
              <Route
                path='/test-plan/sessions/*'
                element={
                  <SuspensedView>
                    <TestSessionPage />
                  </SuspensedView>
                }
              />
              <Route
                path='test-plan/sessions/:testSessionId/results/*'
                element={
                  <SuspensedView>
                    <TestResultPage />
                  </SuspensedView>
                }
              />
            </>
          }

          {/* Site Settings */}
          {
            (auth.hasPermission('api-key-view') || auth.hasPermission('cmsapi-url-view') || auth.hasPermission('callback-url-view')) &&
            <Route
              path='/site-settings/*'
              element={
                <SuspensedView>
                  <SiteSettingsPage />
                </SuspensedView>
              }
            />
          }

          {/* Site Game Provider */}
          {
            auth.hasPermission('site-game-provider-view') &&
            <Route
              path='/site-game-provider/*'
              element={
                <SuspensedView>
                  <SiteGameProviderPage />
                </SuspensedView>
              }
            />
          }

          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </ToolbarContextProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
