import { Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import Table from './components/table/Table';


const gameLogBreadCrumb: Array<PageLink> = [
  {
    title: 'Logs',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const GameLogPage = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={gameLogBreadCrumb}>Game Logs</PageTitle>
            <Table />
          </>
        }
      />
    </Routes>
  )
}

export default GameLogPage